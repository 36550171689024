import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { lighten } from 'polished';
import { modal } from '../../../actions';
import { Modal } from '../../../types/modal';
import { ApplicationState } from '../../../reducers';

import './Modal.scss';
import ModalContent from './ModalContent';
import { colorOrDark } from '../../../utils/color';


interface Props {
  modal: Modal;
  hideModal: () => void;
}

const StyledModalExit = styled.span`
    color: ${props => colorOrDark(props.theme.colors.primary)};

    &:hover {
        color: ${props => lighten(0.2, colorOrDark(props.theme.colors.primary))};
      }
`

class ModalWrapper extends React.Component<Props> {
  private handleClickOutside = (e: any) => {
    const { modal, hideModal } = this.props;
    if (e.target.id === 'modal' && modal.isClosable) hideModal();
  };

  render() {
    const { modal, hideModal } = this.props;
    if (!modal.isVisible && !modal.isPrepared) {
      return null;
    }
    return (
      <>
        <div
          className={`modal ${modal.isVisible ? 'visible' : ''} ${modal.isFullscreen ? 'full-screen' : ''}`}
          id="modal"
          onMouseDown={this.handleClickOutside}
        >
          <div className="modal-body">
            <ModalContent>
              {modal.content}
              {modal.isClosable && (
                <StyledModalExit className="modal-exit" role="button" tabIndex={0} onClick={() => hideModal()}>
                  &times;
                </StyledModalExit>
              )}
            </ModalContent>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...modal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper);
